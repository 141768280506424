export const projectsObj = {
    id: "projects",
    headerText: "PROJECTS"
}

export const aboutObj = {
    id: "about",
    headerText: "ABOUT ME"
}

export const extracurricularsObj = {
    id: "extracurriculars",
    headerText: "EXTRACURRICULARS"
}

export const contactObj = {
    id: "contact",
    headerText: "CONTACT ME"
}