export const itemWOLogoArray = [
    {
        id: "courageous",
        itemText: "COURAGEOUS",
    },
    {
        id: "leader",
        itemText: "A LEADER",
    },
    {
        id: "innovator",
        itemText: "AN INNOVATOR",
    },
    {
        id: "resilient",
        itemText: "RESILIENT",
    },
    {
        id: "creative",
        itemText: "CREATIVE",
    },
    {
        id: "open-minded",
        itemText: "OPEN MINDED",
    },
]