import FRIENDS_HOME from '../../images/friends-home.JPG'
import FRIENDS_TECH from '../../images/friends-tech.JPG'
import FRISBEE from '../../images/frisbee.JPG'

export const SliderData = [
    {
        image: FRIENDS_HOME
    },
    {
        image: FRISBEE
    },
    {
        image: FRIENDS_TECH
    }
]