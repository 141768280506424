import styled from "styled-components";

export const SeparatorContainer = styled.div`
    display: flex;
    justify-content: center;

`

export const Heading = styled.h1`
    
`